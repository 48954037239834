

export const TestimonialsData = [
  {
    quote: "Dr.anvesh sir u r the most heart touching doctor in prime hospital.Becoz the way u r treating patients is like parenting care 🙏.\nHearty all the best to u sir Stay strong and give life’s to many…..👍💐",

    name: 'ALWIN SEKHAR'
  },
  {
    quote: 'Excellent affordable care. Dr anvesh is very patient and dedicated to his patients Neatness and amenities are also good',

    name: 'sruthi kunche'
  },
  {
    quote: 'Dr. Anvesh sir, is well experienced person and treatment looks good..',
    name: 'Naveen Babu'
  },
  {
    quote: 'Atmosphere of the hospitality very well warmed..',
    name: 'Yekula Subramanyam'
  }
];




  