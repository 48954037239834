

export const blogPosts = [
    {
        id: 1,
        title: "Hello World!",
        author: "primehospitalsg",
        date: "July 25, 2024",
        comments: "1 Comment",
        content: "Welcome to WordPress. This is your first post. Edit or delete it, then start writing!",
        category: "Uncategorized",
        link: "#"
    },
    {
        id: 2,
        title: "Covid-19: Increase risk of death following hospitalization",
        author: "primehospitalsg",
        date: "July 29, 2021",
        comments: "No Comments",
        content: "Eventually, you must also offer them attractive and competitive tour packages. You must be able to clearly communicate to your customers what your services are, how they are different from […]",
        category: "Covid-19",
        link: "#"
    },
    {
        id: 3,
        title: "Medical curriculum has outdated information on people",
        author: "primehospitalsg",
        date: "July 26, 2021",
        comments: "No Comments",
        content: "Rishi employee help you to take action for best strategy since it’s important for a company.If you find better idea it’s important for a company to take actions immediate otherwise […]",
        category: "Medical",
        link: "#"
    },
    {
        id: 4,
        title: "Covid-19: Increase risk of death following hospitalization",
        author: "primehospitalsg",
        date: "July 26, 2021",
        comments: "No Comments",
        content: "Our financial assistance boosts you to grow relations and loyalty between employee and market wherever you need anytime. So without wasting any more time, let’s get on to it. First […]",
        category: "Covid-19",
        link: "#"
    },
         {
            id: 5,
            title: "Medical is bound",
            author: "primehospitalsg",
            date: "July 26, 2021",
            comments: "No Comments",
            content: "Our financial assistance boosts you to grow relations and loyalty between employee and market wherever you need anytime. So without wasting any more time, let’s get on to it. First […]",
            category: "Health",
            link: "#"
     
        },

        {
            id: 6,
            title: "Proof That Medical Is Exact What You Are Looking For",
            author: "primehospitalsg",
            date: "July 22, 2021",
            comments: "No Comments",
            content: "You’ve got your announcement in mind, and now it’s time to get it down in words to share with you… Eventually, you must also offer them attractive and competitive tourpackages. […]",
            category: "Covid-19",
            link: "#"
     
        },
        {
            id: 7,
            title: "5 Easy Ways You Can Turn Medical Into Success",
            author: "primehospitalsg",
            date: "July 20, 2021",
            comments: "No Comments",
            content: "You’ve got your announcement in mind, and now it’s time to get it down in words to share with you… Eventually, you must also offer them attractive and competitive tourpackages. […]",
            category: "Covid-19",
            link: "#"
     
        },

];
